
import Vue from 'vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    MyPageWithdrawal: () => import('@/components/views/mypage/Withdrawal.vue'),
  },
})
interface Data {}
interface Methods {}
interface Computed {}
interface Props {}
